import * as React from 'react';
import Layout, { Section } from '../components/layout';
import SEO from '../components/seo';
import { Box, Typography } from '@mui/material';
import { Link } from 'gatsby-theme-material-ui';

const TOSPage: React.FC = () => (
  <Layout gradientHeight={[240, 200, 200]}>
    <SEO title="Terms of Service" />
    <Section height={[240, 200]} background={'uncommonGradient2'} skewedBottom>
      <Box pt={[10, 8]} color="white">
        <Typography variant="h1" color="inherit">
          Terms of Service
        </Typography>
      </Box>
    </Section>
    <Section py={2}>
      <h1>Mobile App Terms of Service</h1>
      <p>
        These Terms of Service govern your use of Kanjiverse, our website
        located at <Link to="https://kanjiverse.com">kanjiverse.com</Link>, and
        any related services provided by App Brewing Company Ltd.
      </p>
      <p>
        When you create a Kanjiverse account or use Kanjiverse, you agree to
        abide by these Terms of Service and to comply with all applicable laws
        and regulations. If you do not agree with these Terms of Service, you
        are prohibited from further using the app, accessing our website, or
        using any other services provided by App Brewing Company Ltd.
      </p>
      <p>
        If you access or download Kanjiverse from (1) the Apple App Store, you
        agree to any Usage Rules set forth in the App Store Terms of Service;
        and/or (2) the Google Play Store, you agree to the Android, Google Inc.
        Terms and Conditions including the Google Apps Terms of Service.
      </p>
      <p>
        We, App Brewing Company Ltd, reserve the right to review and amend any
        of these Terms of Service at our sole discretion. Upon doing so, we will
        update this page and notify you through the app and/or the email address
        you provided when you created your account. Any changes to these Terms
        of Service will take effect immediately from the date of publication.
      </p>
      <p>These Terms of Service were last updated on 8 August 2024.</p>
      <h2>Limitations of Use</h2>
      <p>
        By using Kanjiverse and our website, you warrant on behalf of yourself,
        any entity who you represent who has entered into these Terms of
        Service, and your users that you will not:
      </p>
      <ol>
        <li>
          modify, copy, prepare derivative works of, decompile, or reverse
          engineer Kanjiverse or any software contained within Kanjiverse or on
          our website;
        </li>
        <li>
          remove any copyright or other proprietary notations from Kanjiverse or
          any materials and software contained within Kanjiverse or on our
          website;
        </li>
        <li>
          transfer Kanjiverse or any of its associated materials to another
          person or “mirror” the materials on any other server;
        </li>
        <li>
          knowingly or negligently use Kanjiverse or any of its associated
          services in a way that abuses or disrupts our networks or any other
          service App Brewing Company Ltd provides;
        </li>
        <li>
          use Kanjiverse or its associated services to transmit or publish any
          harassing, indecent, obscene, fraudulent, or unlawful material;
        </li>
        <li>
          use Kanjiverse or its associated services in violation of any
          applicable laws or regulations;
        </li>
        <li>use Kanjiverse to send unauthorized advertising or spam;</li>
        <li>
          harvest, collect, or gather user data without the user’s consent; or
        </li>
        <li>
          use Kanjiverse or its associated services in such a way that may
          infringe the privacy, intellectual property rights, or other rights of
          third parties.
        </li>
      </ol>
      <h2>Intellectual Property</h2>
      <p>
        The intellectual property in the materials in Kanjiverse and on our
        website are owned by or licensed to App Brewing Company Ltd unless
        explicitly attributed to third-parties. Please refer to the About page
        for details of third-party materials and their corresponding copyright
        and license.
      </p>
      <p>
        You may download Kanjiverse, to view, use, and display the application
        on your mobile device for your personal use only. This constitutes the
        grant of a license, not a transfer of title. This license shall
        automatically terminate if you violate any of these restrictions or
        these Terms of Service, and may be terminated by App Brewing Company Ltd
        at any time.
      </p>
      <h2 id="user-generated-content">User-Generated Content</h2>
      <p>
        You retain your intellectual property ownership rights over content you
        submit to us for publication within Kanjiverse and/or on its
        corresponding website. We will never claim ownership of your content,
        but we do require a license from you in order to use it.
      </p>
      <p>
        When you use Kanjiverse or its associated services to post, upload,
        share, or otherwise transmit content covered by intellectual property
        rights, you grant to us a non-exclusive, royalty-free, transferable,
        non-sublicensable, irrevocable, worldwide license to use, distribute,
        modify, run, copy, publicly display, translate, or otherwise create
        derivative works of your content in a manner that is consistent with
        your privacy preferences and our Privacy Policy.
      </p>
      <p>
        Unless explicitly specified otherwise, all content publicly submitted is
        automatically licensed under{' '}
        <Link to="https://creativecommons.org/licenses/by-sa/4.0/">
          CC BY-SA 4.0
        </Link>
        . This means that we, or anyone, can:
        <ul>
          <li>
            Share — copy and redistribute the material in any medium or format
          </li>
          <li>
            Adapt — remix, transform, and build upon the material for any
            purpose, even commercially.
          </li>
        </ul>{' '}
        As long as they respect the following terms:
        <ul>
          <li>
            Attribution — They must give appropriate credit, provide a link to
            the license, and indicate if changes were made. They may do so in
            any reasonable manner, but not in any way that suggests the licensor
            endorses them or their use.
          </li>
          <li>
            ShareAlike — If they remix, transform, or build upon the material,
            they must distribute their contributions under the same license as
            the original.{' '}
          </li>
        </ul>
      </p>
      <p>
        The license you grant us can be terminated at any time by deleting your
        content or account. However, to the extent that we (or our partners)
        have used your content in connection with commercial or sponsored
        content, the license will continue until the relevant commercial or post
        has been discontinued by us.
      </p>
      <p>
        Please remember that once a content has been published, the CC BY-SA 4.0
        license will apply to this content and is irrevocable, even by
        subsequently marking the content as private or deleting your account.
      </p>
      <p>
        You give us permission to use your username and other identifying
        information associated with your account in a manner that is consistent
        with your privacy preferences, and our Privacy Policy.
      </p>
      <h2>AI-Generated Content</h2>
      <p>
        As part of our service, Kanjiverse provides AI-generated content,
        including example sentences and personalized learning materials. This
        content is generated using artificial intelligence models, including
        those provided by OpenAI. While we strive to ensure the accuracy and
        relevance of this content, it is important to understand that
        AI-generated content may not always be perfect or suitable for every
        user or context.
      </p>
      <p>
        You agree to allow Kanjiverse to use OpenAI's services as part of the
        functionality of our app. You also acknowledge that any content
        generated through these services is subject to OpenAI's own terms of
        service and privacy policy, which we encourage you to review.
      </p>
      <p>
        By using the AI-generated content, you acknowledge that it is provided
        "as is" and that Kanjiverse makes no warranties, express or implied,
        regarding the accuracy, completeness, or appropriateness of such content
        for any particular purpose.
      </p>
      <h2>Automatic Updates</h2>
      <p>
        You give us permission to download and install updates to Kanjiverse on
        your device in accordance with your privacy preferences. This permission
        can be revoked at any time by deleting Kanjiverse from your device.
      </p>
      <h2>Liability</h2>
      <p>
        Kanjiverse and the materials in Kanjiverse and on our website are
        provided on an 'as is' basis. To the extent permitted by law, App
        Brewing Company Ltd makes no warranties, expressed or implied, and
        hereby disclaims and negates all other warranties including, without
        limitation, implied warranties or conditions of merchantability, fitness
        for a particular purpose, or non-infringement of intellectual property,
        or other violation of rights.
      </p>
      <p>
        In no event shall App Brewing Company Ltd or its suppliers be liable for
        any consequential loss suffered or incurred by you or any third party
        arising from the use or inability to use Kanjiverse, our website, or any
        other services provided by App Brewing Company Ltd or the materials in
        Kanjiverse, even if App Brewing Company Ltd or an authorized
        representative has been notified, orally or in writing, of the
        possibility of such damage.
      </p>
      <p>
        In the context of this agreement, &ldquo;consequential loss&rdquo;
        includes any consequential loss, indirect loss, real or anticipated loss
        of profit, loss of benefit, loss of revenue, loss of business, loss of
        goodwill, loss of opportunity, loss of savings, loss of reputation, loss
        of use and/or loss or corruption of data, whether under statute,
        contract, equity, tort (including negligence), indemnity, or otherwise.
      </p>
      <p>
        Because some jurisdictions do not allow limitations on implied
        warranties, or limitations of liability for consequential or incidental
        damages, these limitations may not apply to you.
      </p>
      <h2>Accuracy of Materials</h2>
      <p>
        The materials appearing in Kanjiverse or on our website are not
        comprehensive and are for general information purposes only. To the
        extent permitted by law, App Brewing Company Ltd does not warrant or
        make any representations concerning the accuracy, likely results, or
        reliability of the use of the materials in Kanjiverse or on our website,
        or otherwise relating to such materials or on any resources linked to
        Kanjiverse and our website.
      </p>
      <h2>Links</h2>
      <p>
        App Brewing Company Ltd has not reviewed all of the sites linked to
        Kanjiverse or on its corresponding website and is not responsible for
        the contents of any such linked site. The inclusion of any link does not
        imply endorsement, approval, or control by App Brewing Company Ltd of
        the site. Use of any such linked website is at your own risk and we
        strongly advise you make your own investigations with respect to the
        suitability of those sites.
      </p>
      <h2>Notice regarding Apple</h2>
      <p>
        To the extent that you are using or accessing Kanjiverse on an iOS
        device, you acknowledge and agree to the terms of this clause. You
        acknowledge that these Terms of Service are between you and App Brewing
        Company Ltd only, not with Apple Inc. (Apple), and Apple is not
        responsible for Kanjiverse and any materials available in Kanjiverse.
      </p>
      <p>
        Apple has no obligation to furnish you with any maintenance and support
        services with respect to Kanjiverse.
      </p>
      <p>
        If Kanjiverse fails to conform to any applicable warranty, you may
        notify Apple and Apple will refund the purchase price of the mobile
        application to you. To the maximum extent permitted by applicable law,
        Apple will have no other warranty obligation whatsoever with respect to
        Kanjiverse and any other claims, losses, liabilities, damages, costs, or
        expenses attributable to any failure to conform to any warranty will be
        our responsibility.
      </p>
      <p>
        Apple is not responsible for addressing any claims by you or any third
        party relating to Kanjiverse or your use of Kanjiverse, including but
        not limited to (1) product liability claims; (2) any claim that our
        mobile application fails to conform to any applicable legal or
        regulatory requirement; and (3) claims arising under consumer protection
        or similar legislation.
      </p>
      <p>
        Apple is not responsible for the investigation, defence, settlement, and
        discharge of any third-party claim that our mobile application infringes
        that third party’s intellectual property rights.
      </p>
      <p>
        You agree to comply with any applicable third-party terms when using
        Kanjiverse, including any Usage Rules set forth in the Apple App Store
        Agreement of Service.
      </p>
      <p>
        Apple and Apple’s subsidiaries are third-party beneficiaries of these
        Terms of Service, and upon your acceptance of these Terms of Service,
        Apple will have the right (and will be deemed to have accepted the
        right) to enforce these Terms of Service against you as a third-party
        beneficiary of these Terms of Service.
      </p>
      <p>
        You hereby represent and warrant that (1) you are not located in a
        country that is subject to a U.S. Government embargo, or that has been
        designated by the U.S. Government as a "terrorist supporting" country;
        and (2) you are not listed on any U.S. Government list of prohibited or
        restricted parties.
      </p>
      <h2>Right to Terminate</h2>
      <p>
        We may suspend or terminate your Kanjiverse account and right to use
        Kanjiverse and these Terms of Service immediately upon written notice to
        you for any breach of these Terms of Service.
      </p>
      <h2>Severance</h2>
      <p>
        Any term of these Terms of Service which is wholly or partially void or
        unenforceable is severed to the extent that it is void or unenforceable.
        The validity of the remainder of these Terms of Service is not affected.
      </p>
      <h2>Governing Law</h2>
      <p>
        These Terms of Service are governed by and construed in accordance with
        the laws of British Virgin Islands. You irrevocably submit to the
        exclusive jurisdiction of the courts in that State or location.
      </p>
      <h1>Acceptable Use Policy</h1>
      <p>
        This acceptable use policy covers the products, services, and
        technologies (collectively referred to as the &ldquo;Products&rdquo;)
        provided by App Brewing Company Ltd under any ongoing agreement. It’s
        designed to protect us, our customers, and the general Internet
        community from unethical, irresponsible, and illegal activity.
      </p>
      <p>
        App Brewing Company Ltd customers found engaging in activities
        prohibited by this acceptable use policy can be liable for service
        suspension and account termination. In extreme cases, we may be legally
        obliged to report such customers to the relevant authorities.
      </p>
      <p>This policy was last reviewed on 8 August 2024.</p>
      <h2>Fair use</h2>
      <p>
        We provide our facilities with the assumption your use will be
        &ldquo;business as usual&rdquo;, as per our offer schedule. If your use
        is considered to be excessive, then additional fees may be charged, or
        capacity may be restricted.
      </p>
      <p>
        We are opposed to all forms of abuse, discrimination, rights
        infringement, and/or any action that harms or disadvantages any group,
        individual, or resource. We expect our customers and, where applicable,
        their users (&ldquo;end-users&rdquo;) to likewise engage our Products
        with similar intent.
      </p>
      <h2>Customer accountability</h2>
      <p>
        We regard our customers as being responsible for their own actions as
        well as for the actions of anyone using our Products with the customer’s
        permission. This responsibility also applies to anyone using our
        Products on an unauthorized basis as a result of the customer’s failure
        to put in place reasonable security measures.
      </p>
      <p>
        By accepting Products from us, our customers agree to ensure adherence
        to this policy on behalf of anyone using the Products as their end
        users. Complaints regarding the actions of customers or their end-users
        will be forwarded to the nominated contact for the account in question.
      </p>
      <p>
        If a customer — or their end-user or anyone using our Products as a
        result of the customer — violates our acceptable use policy, we reserve
        the right to terminate any Products associated with the offending
        account or the account itself or take any remedial or preventative
        action we deem appropriate, without notice. To the extent permitted by
        law, no credit will be available for interruptions of service resulting
        from any violation of our acceptable use policy.
      </p>
      <h2>Prohibited activity</h2>
      <h3>Copyright infringement and access to unauthorized material</h3>
      <p>
        Our Products must not be used to transmit, distribute or store any
        material in violation of any applicable law. This includes but isn’t
        limited to:
      </p>
      <ol type="i">
        <li>
          any material protected by copyright, trademark, trade secret, or other
          intellectual property right used without proper authorization, and
        </li>
        <li>
          any material that is obscene, defamatory, constitutes an illegal
          threat or violates export control laws.
        </li>
      </ol>
      <p>
        The customer is solely responsible for all material they input, upload,
        disseminate, transmit, create or publish through or on our Products, and
        for obtaining legal permission to use any works included in such
        material.
      </p>
      <h3>SPAM and unauthorized message activity</h3>
      <p>
        Our Products must not be used for the purpose of sending unsolicited
        bulk or commercial messages in violation of the laws and regulations
        applicable to your jurisdiction (“spam”). This includes but isn’t
        limited to sending spam, soliciting customers from spam sent from other
        service providers, and collecting replies to spam sent from other
        service providers.
      </p>
      <p>
        Our Products must not be used for the purpose of running unconfirmed
        mailing lists or telephone number lists (&ldquo;messaging lists&rdquo;).
        This includes but isn’t limited to subscribing email addresses or
        telephone numbers to any messaging list without the permission of the
        email address or telephone number owner, and storing any email addresses
        or telephone numbers subscribed in this way. All messaging lists run on
        or hosted by our Products must be &ldquo;confirmed opt-in&rdquo;.
        Verification of the address or telephone number owner’s express
        permission must be available for the lifespan of the messaging list.
      </p>
      <p>
        We prohibit the use of email lists, telephone number lists or databases
        purchased from third parties intended for spam or unconfirmed messaging
        list purposes on our Products.
      </p>
      <p>
        This spam and unauthorized message activity policy applies to messages
        sent using our Products, or to messages sent from any network by the
        customer or any person on the customer’s behalf, that directly or
        indirectly refer the recipient to a site hosted via our Products.
      </p>
      <h3>Unethical, exploitative, and malicious activity</h3>
      <p>
        Our Products must not be used for the purpose of advertising,
        transmitting, or otherwise making available any software, program,
        product, or service designed to violate this acceptable use policy, or
        the acceptable use policy of other service providers. This includes but
        isn’t limited to facilitating the means to send spam and the initiation
        of network sniffing, pinging, packet spoofing, flooding, mail-bombing,
        and denial-of-service attacks.
      </p>
      <p>
        Our Products must not be used to access any account or electronic
        resource where the group or individual attempting to gain access does
        not own or is not authorized to access the resource (e.g.
        &ldquo;hacking&rdquo;, &ldquo;cracking&rdquo;, &ldquo;phreaking&rdquo;,
        etc.).
      </p>
      <p>
        Our Products must not be used for the purpose of intentionally or
        recklessly introducing viruses or malicious code into our Products and
        systems.
      </p>
      <p>
        Our Products must not be used for purposely engaging in activities
        designed to harass another group or individual. Our definition of
        harassment includes but is not limited to denial-of-service attacks,
        hate-speech, advocacy of racial or ethnic intolerance, and any activity
        intended to threaten, abuse, infringe upon the rights of, or
        discriminate against any group or individual.
      </p>
      <p>
        Other activities considered unethical, exploitative, and malicious
        include:
      </p>
      <ol>
        <li>
          Obtaining (or attempting to obtain) services from us with the intent
          to avoid payment;
        </li>
        <li>
          Using our facilities to obtain (or attempt to obtain) services from
          another provider with the intent to avoid payment;
        </li>
        <li>
          The unauthorized access, alteration, or destruction (or any attempt
          thereof) of any information about our customers or end-users, by any
          means or device;
        </li>
        <li>
          Using our facilities to interfere with the use of our facilities and
          network by other customers or authorized individuals;
        </li>
        <li>
          Publishing or transmitting any content of links that incite violence,
          depict a violent act, depict child pornography, or threaten anyone’s
          health and safety;
        </li>
        <li>
          Any act or omission in violation of consumer protection laws and
          regulations;
        </li>
        <li>Any violation of a person’s privacy.</li>
      </ol>
      <p>
        Our Products may not be used by any person or entity, which is involved
        with or suspected of involvement in activities or causes relating to
        illegal gambling; terrorism; narcotics trafficking; arms trafficking or
        the proliferation, development, design, manufacture, production,
        stockpiling, or use of nuclear, chemical or biological weapons, weapons
        of mass destruction, or missiles; in each case including any affiliation
        with others whatsoever who support the above such activities or causes.
      </p>
      <h3>Unauthorized use of App Brewing Company Ltd property</h3>
      <p>
        We prohibit the impersonation of App Brewing Company Ltd, the
        representation of a significant business relationship with App Brewing
        Company Ltd, or ownership of any App Brewing Company Ltd property
        (including our Products and brand) for the purpose of fraudulently
        gaining service, custom, patronage, or user trust.
      </p>
      <h2>About this policy</h2>
      <p>
        This policy outlines a non-exclusive list of activities and intent we
        deem unacceptable and incompatible with our brand.
      </p>
      <p>
        We reserve the right to modify this policy at any time by publishing the
        revised version on our website. The revised version will be effective
        from the earlier of:
      </p>
      <ul>
        <li>
          the date the customer uses our Products after we publish the revised
          version on our website; or
        </li>
        <li>30 days after we publish the revised version on our website.</li>
      </ul>
    </Section>
  </Layout>
);

export default TOSPage;
